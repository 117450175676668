<template>
  <div class="company-settings" v-loading="loading">

    <div
      v-if="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
    >
      <h2 style="margin-top: 0">Библиотечные цели. Открытые карты</h2>
      <div>Импорт библиотечных целей в открытые карты пользователей. Доступен импорт всех созданных в Библиотеке KPI целей. Все цели импортируются в блок "Библиотечные цели. Выбираются из общей библиотеки.”</div>
      <import-modal
        entity="card_elements_library"
        close-on-success
        @import-succeed="importSucceed"
      >
        <template v-slot:reference>
          <el-button style="margin-top: 20px">
            Импорт
          </el-button>
        </template>
      </import-modal>
    </div>


    <div
      v-if="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
      style="margin-top: 50px"
    >
      <h2>Личные цели. Открытые карты</h2>
      <div>Импорт личных целей в открытые карты пользователей. Доступен импорт целей с проектной и прогрессивной шкалой. Все цели импортируются в блок "Личные цели. Формируются самостоятельно".</div>
      <import-modal
        entity="card_elements"
        close-on-success
        @import-succeed="importSucceed"
      >
        <template v-slot:reference>
          <el-button style="margin-top: 20px">
            Импорт
          </el-button>
        </template>
      </import-modal>
    </div>

    <div
      v-if="$canAndRulesPass(user, $permissions.SETTING.UPDATE) || $canAndRulesPass(user, 'setting.import_cards_closed_route')"
      style="margin-top: 50px"
    >
      <h2>Личные цели. Закрытые карты</h2>
      <div>Импорт личных целей в закрытые карты пользователей. Доступен импорт целей с проектной и прогрессивной шкалой. Все цели импортируются в блок "Личные цели. Формируются самостоятельно".</div>
      <import-modal
        entity="card_elements_closed_route"
        close-on-success
        @import-succeed="importSucceed"
      >
        <template v-slot:reference>
          <el-button style="margin-top: 20px">
            Импорт
          </el-button>
        </template>
      </import-modal>
    </div>



  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";
import {downloader} from "@/mixins/downloader";
import ImportModal from "@/components/exportImport/ImportModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "company-settings",
  components: {ImportModal},
  mixins: [companySettings],

  props: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    importSucceed(){
      this.$notify.success({
        title: 'Завершено',
        message: 'Импорт завершен успешно'
      });
    },
  }
}
</script>


<style lang="scss">

</style>